export default {
    state: () => ({
        stakeGlobalInfo: null,
        stakeWalletInfo: null,
        periodId: 1,
        stakeValue: 0,
        stakeToken: null,
        stakeMaxBalance: null,
        stakeMergedTokens: null,
        userStakes: null,
        transactionId: null,
        stakeNativeToken: null,
        stakeWalletBalances: null,
        stakeCatalogueData: null,
        stakeProcessedCatalogueData: null,
        stakeCatalogueWalletData: null,
        activeMasterAddress: null,
    }),
    mutations: {
        SET_STAKE_GLOBAL(state, value) {
            state.stakeGlobalInfo = value;
        },
        SET_STAKE_WALLET_INFO(state, value) {
            state.stakeWalletInfo = value;
        },
        SET_STAKE_PERIOD_ID(state, value) {
            state.periodId = value;
        },
        SET_STAKE_VALUE(state, value) {
            state.stakeValue = value;
        },
        SET_STAKE_TOKEN(state, value) {
            state.stakeToken = value;
        },
        SET_STAKE_MAX(state, value) {
            state.stakeMaxBalance = value;
        },
        SET_MERGED_TOKENS(state, value) {
            state.stakeMergedTokens = value
        },
        SET_USER_STAKES(state, value) {
            state.userStakes = value
        },
        SET_STAKE_TRANSACTION_ID(state, value) {
            state.transactionId = value
        },
        SET_STAKE_NATIVE(state, value) {
            state.stakeNativeToken = value
        },
        SET_STAKE_BALANCES(state, value) {
            state.stakeWalletBalances = value
        },
        REMOVE_USER_STAKE(state, stakeId) {
            state.userStakes = state.userStakes.filter(stake => stake.id !== stakeId);
        },
        SET_STAKE_CATALOGUE_DATA(state, value) {
            state.stakeCatalogueData = value
        },
        SET_STAKE_CATALOGUE_WALLET_DATA(state, value) {
            state.stakeCatalogueWalletData = value
        },
        SET_STAKE_CATALOGUE_MASTER(state, value) {
            state.activeMasterAddress = value
        },
        SET_STAKE_PROCESSED_CATALOGUE(state, value) {
            state.stakeProcessedCatalogueData = value
        },
        STAKE_CLEAR_STORE(state) {
            state.stakeGlobalInfo = null
            state.stakeWalletInfo = null;
            state.periodId = 1;
            state.stakeValue = 0;
            state.stakeToken = null;
            state.stakeMaxBalance = null;
            state.stakeMergedTokens = null;
            state.userStakes = null;
            state.transactionId = null;
            state.stakeNativeToken = null;
            state.stakeWalletBalances = null;
            state.activeMasterAddress = null;
        }
    },
    actions: {
        STAKE_GLOBAL_INFO({commit}, item) {
            commit('SET_STAKE_GLOBAL', item)
        },
        STAKE_WALLET_INFO({commit}, item) {
            commit('SET_STAKE_WALLET_INFO', item)
        },
        STAKE_PERIOD_ID({commit}, item) {
            commit('SET_STAKE_PERIOD_ID', item)
        },
        STAKE_VALUE({commit}, item) {
            commit('SET_STAKE_VALUE', item)
        },
        STAKE_TOKEN({commit}, item) {
            commit('SET_STAKE_TOKEN', item)
        },
        STAKE_MAX({commit}, item) {
            commit('SET_STAKE_MAX', item)
        },
        STAKE_MERGED_TOKENS({commit}, item) {
            commit('SET_MERGED_TOKENS', item)
        },
        STAKE_USER_STAKES({commit}, item) {
            commit('SET_USER_STAKES', item)
        },
        STAKE_TRANSACTION_ID({commit}, item) {
            commit('SET_STAKE_TRANSACTION_ID', item)
        },
        STAKE_NATIVE_TOKEN({commit}, item) {
            commit('SET_STAKE_NATIVE', item)
        },
        STAKE_WALLET_BALANCES({commit}, item) {
            commit('SET_STAKE_BALANCES', item)
        },
        REMOVE_USER_STAKE_ACTION({commit}, stakeId) {
            commit('REMOVE_USER_STAKE', stakeId);
        },
        STAKE_CLEAR_STORE({commit}) {
            commit('STAKE_CLEAR_STORE');
        },
        STAKE_CATALOGUE_DATA({commit}, item) {
            commit('SET_STAKE_CATALOGUE_DATA', item);
        },
        STAKE_CATALOGUE_WALLET_DATA({commit}, item) {
            commit('SET_STAKE_CATALOGUE_WALLET_DATA', item)
        },
        STAKE_CATALOGUE_MASTER({commit}, item) {
            commit('SET_STAKE_CATALOGUE_MASTER', item)
        },
        STAKE_PROCESSED_CATALOGUE({commit}, item) {
            commit('SET_STAKE_PROCESSED_CATALOGUE', item)
        }
    },
    getters: {
        GET_STAKE_GLOBAL_INFO: state => {
            return state.stakeGlobalInfo;
        },
        GET_STAKE_WALLET_INFO: state => {
            return state.stakeWalletInfo
        },
        GET_STAKE_PERIOD_ID: state => {
            return state.periodId
        },
        GET_STAKE_VALUE: state => {
            return state.stakeValue
        },
        GET_STAKE_TOKEN: state => {
            return state.stakeToken
        },
        GET_STAKE_MAX: state => {
            return state.stakeMaxBalance
        },
        GET_STAKE_MERGED_TOKENS: state => {
            return state.stakeMergedTokens
        },
        GET_STAKE_USER_STAKES: state => {
            return state.userStakes
        },
        GET_STAKE_TRANSACTION_ID: state => {
            return state.transactionId
        },
        GET_STAKE_NATIVE: state => {
            return state.stakeNativeToken
        },
        GET_STAKE_BALANCES: state => {
            return state.stakeWalletBalances
        },
        GET_STAKE_CATALOGUE_DATA: state => {
            return state.stakeCatalogueData
        },
        GET_STAKE_CATALOGUE_WALLET_DATA: state => {
            return state.stakeCatalogueWalletData
        },
        GET_STAKE_MASTER: state => {
            return state.activeMasterAddress
        },
        GET_STAKE_PROCESSED_CATALOGUE: state => {
            return state.stakeProcessedCatalogueData
        }
    }
}
