export default {
  state: () => ({
    contests: null,
    currentContest: null,
    leaderboard: [],
    myPlace: null,
  }),
  mutations: {
    SET_CONTESTS(state, value) {
      state.contests = value;
    },
    SET_CURRENT_CONTEST(state, value) {
      state.currentContest = value;
    },
    SET_CONTEST_LEADERBOARD(state, value) {
      state.leaderboard = value;
    },
    SET_CONTEST_MY_PLACE(state, value) {
      state.myPlace = value;
    },
  },
  actions: {
    SAVE_CONTESTS({ commit }, item) {
      commit('SET_CONTESTS', item);
    },
    SAVE_CURRENT_CONTEST({ commit }, item) {
      commit('SET_CURRENT_CONTEST', item);
    },
    SAVE_CONTEST_LEADERBOARD({ commit }, item) {
      commit('SET_CONTEST_LEADERBOARD', item);
    },
    SAVE_CONTEST_MY_PLACE({ commit }, item) {
      commit('SET_CONTEST_MY_PLACE', item);
    },
  },
  getters: {
    GET_CONTESTS: (state) => {
      return state.contests;
    },
    GET_CURRENT_CONTEST: (state) => {
      return state.currentContest;
    },
    GET_CONTEST_LEADERBOARD: (state) => {
      return state.leaderboard;
    },
    GET_CONTEST_MY_PLACE: (state) => {
      return state.myPlace;
    },
  },
};
