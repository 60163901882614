import {
  yandexMetrikaConfig,
  googleAnalyticsConfig,
  telemetreeConfig,
  telegramAnalyticsConfig,
} from '@/configs';

let telemetreeBuilder = null;

const init = {
  telemetree() {
    console.log('Initializing Telemetree with config');
    if (window.telemetree) {
      telemetreeBuilder = window.telemetree(telemetreeConfig);
      console.log('Telemetree initialized successfully.');
    } else {
      console.error('Telemetree library is not loaded.');
    }
  },

  googleAnalytics() {
    if (typeof window.gtag === 'undefined') {
      const script = document.createElement('script');
      script.src = 'https://www.googletagmanager.com/gtag/js?id=' + googleAnalyticsConfig.id;
      script.async = true;

      script.onload = function () {
        window.dataLayer = window.dataLayer || [];
        window.gtag = function () {
          dataLayer.push(arguments);
        };
        gtag('js', new Date());
        gtag('config', googleAnalyticsConfig.id);
      };

      script.onerror = function () {
        console.error('google analytics load error');
      };

      document.head.appendChild(script);
    } else {
      window.dataLayer = window.dataLayer || [];
      window.gtag = function () {
        dataLayer.push(arguments);
      };
      gtag('js', new Date());
      gtag('config', googleAnalyticsConfig.id);
    }
  },

  yandexMetrika() {
    (function (m, e, t, r, i, k, a) {
      m[i] =
        m[i] ||
        function () {
          (m[i].a = m[i].a || []).push(arguments);
        };
      m[i].l = 1 * new Date();
      (k = e.createElement(t)),
        (a = e.getElementsByTagName(t)[0]),
        (k.async = 1),
        (k.src = r),
        a.parentNode.insertBefore(k, a);
    })(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym');

    if (window.ym) {
      window.ym(yandexMetrikaConfig.id, 'init', yandexMetrikaConfig.options);
    }
  },

  telegramAnalytics() {
    if (window.Telegram?.WebApp?.initData) {
      window.telegramAnalytics.init(telegramAnalyticsConfig);
    }
  },
};

const actions = {
  trackWithYandexMetrika(eventName, eventData) {
    if (window.ym) {
      window.ym(yandexMetrikaConfig.id, 'reachGoal', eventName, eventData);
    }
  },

  trackWithGoogleAnalytics(eventName, eventData) {
    if (window.gtag) {
      window.gtag('event', eventName, eventData);
    }
  },

  trackWithTelemetree(eventName, eventData) {
    if (window?.Telegram?.WebApp?.platform !== 'unknown' && telemetreeBuilder) {
      telemetreeBuilder.track(eventName, eventData);
    }
  },
};

export const tracking = {
  initAllAnalytics() {
    if (window.Telegram?.WebApp?.initData && window?.Telegram?.WebApp?.platform !== 'unknown') {
      init.telemetree();
      init.telegramAnalytics();
    }
    init.yandexMetrika();
    init.googleAnalytics();
  },

  trackEvent(eventName, eventData = {}) {
    actions.trackWithYandexMetrika(eventName, eventData);
    actions.trackWithGoogleAnalytics(eventName, eventData);
    actions.trackWithTelemetree(eventName, eventData);
  },
};
