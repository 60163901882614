export const Events = Object.freeze({
  DEX_EXCHANGE: 'dex_exchange',
  CASHBACK_PAGE_OPEN: 'cashback_page_open',
  ROUTE_DETAILS_OPEN: 'route_details_open',
  EXCHANGE_DETAILS_OPEN: 'swap_details_open',
  SHARE_BUTTON_CLICK: 'share_button_click',
  EXPERT_MODE_TOGGLE: 'expert_mode_toggle',
  CONNECT_WALLET: 'connect_wallet',
  CONTEST_PAGE_OPEN: 'contest_page_open',
  TOKEN_IMPORT: 'token_import',
  STAKE_TOKEN_TOGGLE: 'stake_token_toggle',
  STAKE_PERIOD_TOGGLE: 'stake_period_toggle',
  STAKE_ACTION_INFO: 'stake_action_info',
  CHART_DISPLAY_TOGGLE: 'chart_display_toggle'
});
