import { createRouter, createWebHistory } from 'vue-router';
import AppWrapper from '@/views/AppWrapper.vue';

const routes = [
	{
		path: '',
		name: 'Main',
		redirect: {name: "Dex"},
	},
	{
		path: '/',
		name: 'AppWrapper',
		component: AppWrapper,
		children: [
			{
				path: 'dex',
				name: 'Dex',
				component: () => import("@/views/DexPage.vue"),
			},
			{
				path: 'referral',
				name: 'Referral',
				component: () => import("@/views/ReferralPage.vue")
			},
			{
				path: 'contests',
				name: 'Contests',
				component: () => import("@/views/TradingContests.vue")
			},
			{
				path: 'contests/:id',
				name: 'ContestPage',
				component: () => import("@/views/ContestPage.vue"),
			},
			{
				path: 'cashback',
				name: 'Cashback',
				component: () => import("@/views/CashbackPage.vue"),
			},
			{
				path: 'cashback/:id',
				name: 'CashbackItemPage',
				component: () => import("@/views/CashbackItemPage.vue"),
				props: true,
			},
			{
				path: 'claim',
				name: 'Claim',
				component: () => import("@/views/ClaimCenter.vue")
			},
			{
				path: 'stake/:name?',
				name: 'Stake',
				component: () => import("@/views/StakePage.vue"),
				props: true,
				beforeEnter: (to, from, next) => {
					if (!to.params.name) {
						next({ name: 'StakeCatalogue' });
					} else {
						next();
					}
				}
			},
			{
				path: 'stakes',
				name: 'StakeCatalogue',
				component: () => import("@/views/StakeCataloguePage.vue")
			}
		]
	},
	// {
	// 	path: '/random-presale',
	// 	name: 'Presale',
	// 	component: PresalePage,
	// },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  // scrollBehavior(to, from, savedPosition) {
  // 	if (to.hash) {
  // 		return {
  // 			el: to.hash,
  // 		}
  // 	} else {
  // 		return {
  // 			left: 0,
  // 			top: 0
  // 		}
  // 	}
  // }
});

export default router;
