import { createStore } from 'vuex'
import dex from "@/store/dex/dex";
import presale from "@/store/presale/presale";
import technicalWorks from "@/store/technical-works/technical-works";
import chart from "@/store/chart/chart";
import settings from "@/store/settings/settings";
import referral from "@/store/referral/referral";
import cashback from "@/store/cashback/cashback";
import claim from "@/store/claim/claim";
import contests from "@/store/contests/contests.js";
import dexSettings from "@/store/dex-settings/dexSettings.js";
import staking from "@/store/staking/staking"

const store = createStore ({
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
        dex: dex,
        dexSettings: dexSettings,
        presale: presale,
        works: technicalWorks,
        chart: chart,
        settings: settings,
        cashback: cashback,
	    referrals: referral,
        claim: claim,
        staking: staking,
        contests: contests
    }
})

export default store;
