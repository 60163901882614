import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import components from '@/components/ui';
import { i18n } from './translate';
import { Buffer } from 'buffer';
import { tracking } from '@/tracking';
import * as Sentry from '@sentry/vue';

window.Buffer = Buffer;

const app = createApp(App, {});

components.forEach((component) => {
  app.component(component.name, component);
});

Sentry.init({
  app,
  dsn: 'https://5dee281d4965ddb4174bfca569620acb@sentry.swap.coffee/6',
  integrations: [Sentry.browserTracingIntegration({ router })],
  // Tracing
  tracesSampleRate: 0.3, //  Capture 30% of the transactions
});

app.use(i18n);
app.use(router);
app.use(store);
app.mount('#app');

window.addEventListener('load', () => {
  setTimeout(() => {
    tracking.initAllAnalytics();
  }, 4000);
});
