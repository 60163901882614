export default {
  state: () => ({
    claimHistory: null,
    claimInfo: null,
    activeFilter: 'all',
  }),
  mutations: {
    SET_CLAIM_HISTORY(state, value) {
      state.claimHistory = value;
    },
    SET_CLAIM_INFO(state, value) {
      state.claimInfo = value;
    },
    SET_CLAIM_FILTER(state, value) {
      state.activeFilter = value
    },
  },
  actions: {
    CLAIM_HISTORY({ commit }, item) {
      commit('SET_CLAIM_HISTORY', item);
    },
    CLAIM_INFO({ commit }, item) {
      commit('SET_CLAIM_INFO', item);
    },
    CLAIM_FILTER({commit}, item) {
      commit('SET_CLAIM_FILTER', item)
    },
  },
  getters: {
    GET_CLAIM_HISTORY: (state) => {
      return state.claimHistory;
    },
    GET_CLAIM_INFO: (state) => {
      return state.claimInfo;
    },
    GET_CLAIM_FILTER: (state) => {
      return state.activeFilter
    },
  },
};
