export default {
  state: () => ({
    presaleAmount: 0,
    swapToken: null,
    tonBalance: 0,
    fairlaunchBalance: 0,
  }),
  mutations: {
    SAVE_PRESALE_AMOUNT(state, item) {
      state.presaleAmount = item;
    },
    SAVE_SWAP_TOKEN(state, item) {
      state.swapToken = item;
    },
    SAVE_TON_BALANCE(state, item) {
      state.tonBalance = item;
    },
    SAVE_FAIRLAUNCH_BALANCE(state, item) {
      state.fairlaunchBalance = item;
    },
    CLEAR_PRESALE_STATE(state) {
      state.presaleAmount = 0;
      state.swapToken = null;
      state.tonBalance = 0;
      state.fairlaunchBalance = 0;
    },
  },
  actions: {
    PRESALE_SEND_AMOUNT({ commit }, item) {
      commit('SAVE_PRESALE_AMOUNT', item);
    },
    PRESALE_SWAP_TOKEN({ commit }, item) {
      commit('SAVE_SWAP_TOKEN', item);
    },
    PRESALE_TON_BALANCE({ commit }, item) {
      commit('SAVE_TON_BALANCE', item);
    },
    FAIRLAUNCH_BALANCE({ commit }, item) {
      commit('SAVE_FAIRLAUNCH_BALANCE', item);
    },
    CLEAR_PRESALE_STORE({ commit }) {
      commit('CLEAR_PRESALE_STATE');
    },
  },
  getters: {
    GET_PRESALE_AMOUNT: (state) => {
      return state.presaleAmount;
    },
    GET_SWAP_TOKEN: (state) => {
      return state.swapToken;
    },
    GET_TON_BALANCE: (state) => {
      return state.tonBalance;
    },
    GET_FAIRLAUNCH_BALANCE: (state) => {
      return state.fairlaunchBalance;
    },
    GET_CURRENT_TOKEN_PRICE: (state) => {
      let balance = state.fairlaunchBalance / Math.pow(10, 9);
      let price = 0;

      if (balance > 0) {
        price = (balance / 100000).toFixed(2);
      }

      if (price < 1.2) {
        return 1.2;
      } else if (price > 2.4) {
        return 2.4;
      } else {
        return price;
      }
    },
  },
};
