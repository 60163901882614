export const telemetreeConfig = {
  projectId: import.meta.env.VITE_TELEMETREE_PROJECT_ID,
  apiKey: import.meta.env.VITE_TELEMETREE_API_KEY,
  appName: import.meta.env.VITE_TELEMETREE_APP_NAME,
  isTelegramContext: true,
};

export const yandexMetrikaConfig = {
  id: import.meta.env.VITE_YANDEX_METRIKA_ID,
  options: {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: false,
  },
};

export const googleAnalyticsConfig = {
  id: import.meta.env.VITE_GOOGLE_ANALYTICS_ID,
};

export const telegramAnalyticsConfig = {
  token: import.meta.env.VITE_TELEGRAM_ANALYTICS_TOKEN,
  appName: import.meta.env.VITE_TELEGRAM_ANALYTICS_APP_NAME,
};
