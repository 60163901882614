import TonApi from '@/api/tonApi';
import DexApiV2 from '@/api/dexApiV2';
import TokensApi from '@/api/tokensApi';
import GeoApi from '@/api/geoApi';

export default {
  computed: {
    getRouteName() {
      return this.$route.name;
    },
    tonApi() {
      return new TonApi();
    },
    dexApiV2() {
      return new DexApiV2();
    },
    tokensApi() {
      return new TokensApi();
    },
    geoApi() {
      return new GeoApi();
    },
    getTrimAddress() {
      let address = this.GET_DEX_WALLET?.userFriendlyAddress;
      if (address) {
        let stringLength = 4;
        let firstHalf = address.substring(0, stringLength);
        let secondHalf = address.substring(address.length - stringLength);
        return firstHalf + '...' + secondHalf;
      } else {
        return '';
      }
    },
  },
};
