export default {
  state: () => ({
    referralInfo: null,
    referralList: null,
  }),
  mutations: {
    SET_REFERRAL_INFO(state, value) {
      state.referralInfo = value;
    },
    SET_REFERRAL_LIST(state, value) {
      state.referralList = value;
    },
  },
  actions: {
    REFERRAL_INFO({ commit }, item) {
      commit('SET_REFERRAL_INFO', item);
    },
    REFERRAL_LIST({ commit }, item) {
      commit('SET_REFERRAL_LIST', item);
    },
  },
  getters: {
    GET_REFERRAL_INFO: (state) => {
      return state.referralInfo;
    },
    GET_REFERRAL_LIST: (state) => {
      return state.referralList;
    },
  },
};
