export default {
  state: () => ({
    cashbackList: [],
    selectedItem: null,
  }),
  mutations: {
    SET_CASHBACK_LIST(state, value) {
      state.cashbackList = value;
    },
    SET_SELECTED_CASHBACK(state, value) {
      state.selectedItem = value;
    },
  },
  actions: {
    CASHBACK_LIST({ commit }, item) {
      commit('SET_CASHBACK_LIST', item);
    },
    SELECTED_CASHBACK({ commit }, item) {
      commit('SET_SELECTED_CASHBACK', item);
    },
  },
  getters: {
    GET_CASHBACK_LIST: (state) => {
      return state.cashbackList;
    },
    GET_SELECTED_CASHBACK: (state) => {
      return state.selectedItem;
    },
  },
};
