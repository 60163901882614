import axios from 'axios';
import Api from '@/api/instance.js';

export default class DexApiV2 extends Api {
  constructor() {
    const ax = axios.create({
      baseURL: 'https://backend.swap.coffee/',
    });

    super(ax);
  }

  getRoute(requestParams) {
    return this.request('/v1/route', JSON.stringify(requestParams), 'POST');
  }

  getStakeTransaction(senderAddress, tokenAddress, amount, referralName) {
    let body = {
      sender_address: senderAddress,
      amount: amount,
      token_address: tokenAddress,
    };

    if (referralName) {
      body.referral_name = referralName;
    }

    return this.request('/v2/stake/ton/transaction', JSON.stringify(body), 'POST');
  }

  getUnstakeTransaction(senderAddress, tokenAddress, amount) {
    let body = {
      sender_address: senderAddress,
      amount: amount,
      token_address: tokenAddress,
    };

    return this.request('/v2/unstake/ton/transaction', JSON.stringify(body), 'POST');
  }

  getRouteTransactions(route, senderAddress, slippage, referralName) {
    let body = {
      sender_address: senderAddress,
      slippage: slippage,
      paths: route.paths,
    };
    if (referralName) {
      body.referral_name = referralName;
    }

    return this.request('/v2/route/transactions', JSON.stringify(body), 'POST');
  }

  getTransactions(query_id) {
    return this.request(`/v1/route/result?query_id=${query_id}`);
  }

  getWalletVersion(address) {
    return this.request(`/v1/ton/wallet/${address}/version`);
  }

  getBalance(address) {
    return this.request(`/v1/ton/wallet/${address}/balance`);
  }

  getTransactionsHistory(address, tokens, verification) {
    const headers = {
      'x-verify': JSON.stringify(verification),
    };
    let firstTokenAddress = null;
    let secondTokenAddress = null;

    if (tokens.first) {
      firstTokenAddress = tokens?.first;
    }
    if (tokens.second) {
      secondTokenAddress = tokens?.second;
    }
    return this.requestWithHeaders(
      `v1/profile/${address}/transactions?token=${firstTokenAddress}&token2=${secondTokenAddress}`,
      null,
      headers,
      'GET',
    );
  }

  getReferralInfo(address, verification) {
    const headers = {
      'x-verify': JSON.stringify(verification),
    };
    return this.requestWithHeaders(`/v1/referral/${address}`, null, headers, 'GET');
  }

  bindReferralLink(address, refAddress, verification) {
    const headers = {
      'x-verify': JSON.stringify(verification),
    };
    return this.requestWithHeaders(
      `/v1/referral/${address}?referral=${refAddress}`,
      null,
      headers,
      'POST',
    );
  }

  getReferralList(address, verification, opts) {
    const headers = {
      'x-verify': JSON.stringify(verification),
    };
    let page = 1;
    let size = 10;
    if (opts) {
      page = opts.page;
      size = opts.size;
    }
    return this.requestWithHeaders(
      `/v1/referral/${address}/list?page=${page}&size=${size}`,
      null,
      headers,
      'GET',
    );
  }

  readStorage(address, verification) {
    const headers = {
      'x-verify': JSON.stringify(verification),
    };
    return this.requestWithHeaders(`/v1/profile/${address}/settings`, null, headers, 'GET');
  }

  writeStorage(address, verification, body) {
    const headers = {
      'x-verify': JSON.stringify(verification),
    };
    const data = {
      body: body,
    };
    return this.requestWithHeaders(`/v1/profile/${address}/settings`, data, headers, 'POST');
  }

  getCashbacks(id = null) {
    try {
      let url = id ? `/v1/cashback/${id}` : '/v1/cashback/?size=100';
      return this.request(url);
    } catch (error) {
      console.error(error);
    }
  }

  getClaimInfo(address, type, verification, opts) {
    let page = 1;
    let size = 50;

    if (opts) {
      page = opts.page;
      size = opts.size;
    }
    const headers = {
      'x-verify': JSON.stringify(verification),
    };
    return this.requestWithHeaders(
      `/v1/claim/${address}?type=${type}&page=${page}&size=${size}`,
      null,
      headers,
      'GET',
    );
  }

  // getClaimStats(address, type, verification) {
  //     const headers = {
  //         'x-verify': JSON.stringify(verification)
  //     }
  //     return this.requestWithHeaders(`/v1/claim/${address}?type=${type}`, null, headers, 'GET')
  // }
  //
  // getClaimTokensList(address, type, verification, opts) {
  //     const headers = {
  //         'x-verify': JSON.stringify(verification)
  //     }
  //     let offset = 0
  //     let limit = 50
  //
  //     if (opts) {
  //         offset = opts.offset
  //         limit = opts.limit
  //     }
  //     return this.requestWithHeaders(`/v1/claim/${address}/tokens?type=${type}&offset=${offset}&limit=${limit}`, null, headers, 'GET')
  // }

  claimTokens(address, type, tokens, verification) {
    const headers = {
      'x-verify': JSON.stringify(verification),
    };
    return this.requestWithHeaders(`/v1/claim/${address}?type=${type}`, tokens, headers, 'POST');
  }

  getClaimHistory(address, type, verification, opts) {
    const headers = {
      'x-verify': JSON.stringify(verification),
    };
    let page = 1;
    let size = 10;

    if (opts) {
      page = opts.page;
      size = opts.size;
    }
    return this.requestWithHeaders(
      `/v1/claim/${address}/state?type=${type}&page=${page}&size=${size}`,
      null,
      headers,
      'GET',
    );
  }

  getClaimStatus(idList) {
    let query = '';
    for (let i = 0; idList.length > i; i++) {
      let prefix = '';
      if (i > 0) {
        prefix = '&';
      }
      query += prefix + `query_id=${idList[i]}`;
    }
    return this.request(`/v1/claim/result?${query}`, null, 'GET');
  }

  getContests() {
    return this.request('/v1/contests');
  }

  getContestById(id) {
    return this.request(`/v1/contests/${id}`);
  }

  getTopForContest(id) {
    return this.request(`/v1/contests/${id}/top`);
  }

    getUserStatsForContest(id, address, verification) {
        const headers = {
            'x-verify': JSON.stringify(verification)
        }
        return this.requestWithHeaders(`/v1/contests/${id}/user/${address}`, null, headers)
    }

    getStakingGlobalInfo(masterAddress) {
        return this.request(`/v2/staking/${masterAddress}`, null, "GET");
    }

    getStakingUserInfo(address, verification, masterAddress) {
        const headers = {
            'x-verify': JSON.stringify(verification)
        };
        return this.requestWithHeaders(`/v2/staking/${masterAddress}/${address}`, null, headers, "GET");
    }

    async createStakingPosition(data, masterAddress) {
        const { address, assetAddress, amountToStakeRaw, periodId, verification } = data;

        const headers = {
            'x-verify': JSON.stringify(verification)
        };

        const body = {
            asset_address: assetAddress,
            amount_to_stake_raw: amountToStakeRaw,
            period_id: periodId
        };
        return this.requestWithHeaders(`/v2/staking/${masterAddress}/positions/${address}`, body, headers, "POST");
    }


    extendStakingPosition(address, positionId, periodId, verification, masterAddress) {
        const headers = {
            'x-verify': JSON.stringify(verification)
        };
        const body = {
            period_id: periodId
        };
        return this.requestWithHeaders(`/v2/staking/${masterAddress}/positions/${address}/${positionId}`, JSON.stringify(body), headers, "POST");
    }

    closeStakingPosition(address, positionId, verification, masterAddress) {
        const headers = {
            'x-verify': JSON.stringify(verification)
        };
        return this.requestWithHeaders(`/v2/staking/${masterAddress}/positions/${address}/${positionId}`, null, headers, "DELETE");
    }

    getStakingTransactionStatus(query_id) {
        return this.request(`/v2/staking/result?query_id=${query_id}`, null, "GET");
    }

    getStakingTokenBalance(address, verification, masterAddress) {
        const headers = {
            'x-verify': JSON.stringify(verification)
        }
        return this.requestWithHeaders(`/v2/staking/${masterAddress}/${address}/balances`, null, headers, "GET");
    }

    getStakingCatalogueList() {
      return this.request(`/v2/staking/list`, null, "GET");
    }
}


