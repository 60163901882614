<template>
  <div class="wallet-settings__background" @click.self="$emit('closeSettings')">
    <div class="wallet-settings__wrapper">
      <div class="wallet-settings__top-content">
        <p class="wallet-settings__top-title">
          {{ $t('dexWallet.title') }}
        </p>
        <button class="wallet-settings__close-btn" @click="$emit('closeSettings')">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <g opacity="0.8">
              <path
                d="M19.2807 18.2194C19.3504 18.289 19.4056 18.3718 19.4433 18.4628C19.4811 18.5539 19.5005 18.6514 19.5005 18.75C19.5005 18.8485 19.4811 18.9461 19.4433 19.0372C19.4056 19.1282 19.3504 19.2109 19.2807 19.2806C19.211 19.3503 19.1283 19.4056 19.0372 19.4433C18.9462 19.481 18.8486 19.5004 18.7501 19.5004C18.6515 19.5004 18.5539 19.481 18.4629 19.4433C18.3718 19.4056 18.2891 19.3503 18.2194 19.2806L12.0001 13.0603L5.78068 19.2806C5.63995 19.4213 5.44907 19.5004 5.25005 19.5004C5.05103 19.5004 4.86016 19.4213 4.71943 19.2806C4.5787 19.1399 4.49963 18.949 4.49963 18.75C4.49963 18.551 4.5787 18.3601 4.71943 18.2194L10.9397 12L4.71943 5.78061C4.5787 5.63988 4.49963 5.44901 4.49963 5.24999C4.49963 5.05097 4.5787 4.8601 4.71943 4.71936C4.86016 4.57863 5.05103 4.49957 5.25005 4.49957C5.44907 4.49957 5.63995 4.57863 5.78068 4.71936L12.0001 10.9397L18.2194 4.71936C18.3602 4.57863 18.551 4.49957 18.7501 4.49957C18.9491 4.49957 19.1399 4.57863 19.2807 4.71936C19.4214 4.8601 19.5005 5.05097 19.5005 5.24999C19.5005 5.44901 19.4214 5.63988 19.2807 5.78061L13.0604 12L19.2807 18.2194Z"
                fill="white"
              />
            </g>
          </svg>
        </button>
      </div>
      <div class="wallet-settings__main">
        <p class="wallet-settings__info-text">
          {{ $t('dexWallet.balance') }}
        </p>
        <p class="wallet-settings__info-amount">${{ getTotalBalance }}</p>
        <div v-show="GET_DEX_WALLET !== null" class="wallet-settings__dex-wallet">
          <div class="wallet-settings__dex-block">
            <img :src="walletImage" alt="wallet-icon" class="wallet-settings__dex-image" />
            <p class="wallet-settings__dex-address">
              {{ getTrimAddress }}
            </p>
          </div>
          <button class="wallet-settings__dex-disconnect-btn" @click="connectToTelegramBot">
            <svg viewBox="0 0 17 18" class="h-6 w-6" xmlns="http://www.w3.org/2000/svg">
              <path
                d="m.32 8.22c4.81-2.07 8.02-3.43 9.62-4.08 3.48-1.43 4.87-1.96 5.61-2.13.23-.06 1.45.29 1.45 1.18 0 2.58-1.32 8.8-1.87 11.67-.23 1.22-1.88 1.6-3.74.45-1.47-.91-2.27-1.52-3.68-2.44-1.63-1.05-.58-1.63.35-2.58.25-.25 4.47-4.03 4.55-4.37.01-.04.02-.2-.08-.29-.09-.08-.24-.05-.34-.03-.14.03-2.46 1.54-6.96 4.53-.66.44-1.25.66-1.79.65-.59-.02-1.15-.05-2.56-.6-.94-.37-1.13-1.02-.56-1.96z"
                fill="currentColor"
                fill-rule="evenodd"
              />
            </svg>
          </button>
          <!--					<button class="wallet-settings__dex-btn"></button>-->
          <button class="wallet-settings__dex-disconnect-btn" @click="$emit('logout')">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <g opacity="0.8">
                <path
                  d="M8.75 16.875C8.75 17.0408 8.68415 17.1997 8.56694 17.3169C8.44973 17.4342 8.29076 17.5 8.125 17.5H3.75C3.41848 17.5 3.10054 17.3683 2.86612 17.1339C2.6317 16.8995 2.5 16.5815 2.5 16.25V3.75C2.5 3.41848 2.6317 3.10054 2.86612 2.86612C3.10054 2.6317 3.41848 2.5 3.75 2.5H8.125C8.29076 2.5 8.44973 2.56585 8.56694 2.68306C8.68415 2.80027 8.75 2.95924 8.75 3.125C8.75 3.29076 8.68415 3.44973 8.56694 3.56694C8.44973 3.68415 8.29076 3.75 8.125 3.75H3.75V16.25H8.125C8.29076 16.25 8.44973 16.3158 8.56694 16.4331C8.68415 16.5503 8.75 16.7092 8.75 16.875ZM17.3172 9.55781L14.1922 6.43281C14.1048 6.34531 13.9934 6.2857 13.8721 6.26154C13.7508 6.23739 13.625 6.24977 13.5108 6.29711C13.3965 6.34446 13.2988 6.42464 13.2302 6.52751C13.1615 6.63038 13.1249 6.75132 13.125 6.875V9.375H8.125C7.95924 9.375 7.80027 9.44085 7.68306 9.55806C7.56585 9.67527 7.5 9.83424 7.5 10C7.5 10.1658 7.56585 10.3247 7.68306 10.4419C7.80027 10.5592 7.95924 10.625 8.125 10.625H13.125V13.125C13.1249 13.2487 13.1615 13.3696 13.2302 13.4725C13.2988 13.5754 13.3965 13.6555 13.5108 13.7029C13.625 13.7502 13.7508 13.7626 13.8721 13.7385C13.9934 13.7143 14.1048 13.6547 14.1922 13.5672L17.3172 10.4422C17.3753 10.3841 17.4214 10.3152 17.4529 10.2393C17.4843 10.1635 17.5005 10.0821 17.5005 10C17.5005 9.91787 17.4843 9.83654 17.4529 9.76066C17.4214 9.68479 17.3753 9.61586 17.3172 9.55781Z"
                  fill="white"
                />
              </g>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import computedMixins from '@/mixins/computedMixins.js';
import methodsMixins from '@/mixins/methodsMixins.js';

export default {
  name: 'DexWalletSettingsPopup',
  mixins: [computedMixins, methodsMixins],
  props: {
    walletImage: {
      type: String,
      required: false,
    },
  },
  methods: {
    async connectToTelegramBot() {
      let bridgeConnection = localStorage.getItem('ton-connect-storage_bridge-connection');

      let response = await fetch('https://bot.swap.coffee/wallets/store', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          bridge_connection: bridgeConnection,
        }),
      });

      if (response.ok) {
        let data = await response.json();
        let connectionId = data.connection_id;

        let url = `https://t.me/swapcoffeebot?start=${connectionId}`;

        window.open(url, '_blank');
      }
    },
  },
  computed: {
    ...mapGetters(['GET_DEX_WALLET', 'GET_DEAL_CONDITIONS', 'GET_USER_TOKENS']),
    getYourTokens() {
      return this.GET_USER_TOKENS.filter((item) => item.balance > 0);
    },
    getTotalBalance() {
      let sum = 0;
      this.getYourTokens.forEach((item) => {
        sum += item?.balance * item?.price_usd;
      });

      return this.prettyNumber(sum, 4);
    },
  },
};
</script>

<style scoped>
.wallet-settings__background {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(10, 7, 6, 0.8);
}

.wallet-settings__wrapper {
  height: 222px;
  width: 440px;
  padding: 18px;
  border-radius: 20px;
  background: var(--iface-main-bg);
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.wallet-settings__top-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wallet-settings__close-btn {
  width: 24px;
  height: 24px;
  border: none;
  outline: none;
  background: transparent;
}

.wallet-settings__top-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
}

.wallet-settings__info-text {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.4px;
  opacity: 0.5;
}

.wallet-settings__main {
  background: var(--iface-white4);
  padding: 14px;
  border-radius: 14px;
  gap: 14px;
}

.wallet-settings__info-amount {
  margin-top: 6px;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 15px;
}

.wallet-settings__dex-wallet {
  display: flex;
  align-items: center;
  gap: 6px;
}

.wallet-settings__dex-disconnect-btn {
  transition: 0.2s;
  width: 44px;
  height: 36px;
  border-radius: 14px;
  padding: 8px 12px;
  border: 1px solid var(--iface-white14);
  background: transparent;
}

.wallet-settings__dex-btn {
  transition: 0.2s;
  width: 44px;
  height: 36px;
  border-radius: 14px;
  padding: 8px 12px;
  border: 1px solid var(--iface-white14);
  background: transparent url('@/assets/dex/wallet-settings.svg') center no-repeat;
}

.wallet-settings__dex-btn:hover {
  border: 1px solid var(--iface-white24);
}

.wallet-settings__dex-disconnect-btn:hover {
  border: 1px solid var(--iface-white24);
}

.wallet-settings__dex-block {
  transition: 0.2s;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  border-radius: 14px;
  background: transparent;
  gap: 8px;
  cursor: pointer;
  border: 1px solid var(--iface-white14);
}

.wallet-settings__dex-block:hover {
  border: 1px solid var(--iface-white24);
}

.wallet-settings__dex-address {
  max-width: 85px;
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;
  line-height: 16px;
}

.wallet-settings__dex-image {
  border-radius: 100px;
  width: 20px;
  height: 20px;
}

.theme-light svg path {
  fill: #141414;
}
</style>
